import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46173621 = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _a60ce88a = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _159959ec = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _7dbccf8d = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _29a175a3 = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _4a89a70d = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _7070bfec = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _17d4344a = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _01824331 = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _d5312866 = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _bef1d2de = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _373e6622 = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _3da732e6 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _5c2bdce6 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _b50d274a = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _4ce2d249 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _68dbc5d0 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _66a75ff2 = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _fd943efe = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _3df99184 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _0c6dad4e = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _0fd8d878 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _3d8433d4 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _b4ab9cb8 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _1e52cc8e = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _c3737dec = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _dbb527b0 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _17de8e50 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _60277f76 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _1e3accfb = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _3acc8db4 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _6a7c8c3d = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _4b5fc2aa = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _065d609a = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _1fef5b7c = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _0653945e = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _baab2e6e = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _de1fdafe = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _aec34036 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _79a6599a = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _40f7c8e0 = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _02d95f18 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _4b6308c9 = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _61bf99c1 = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _107f9d0e = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _67a16426 = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _34b1a33b = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _14f19406 = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _58b316e5 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _433439b8 = () => interopDefault(import('../src/modules/marketing/pages/frontend/welcome-answer' /* webpackChunkName: "" */))
const _180fb1f5 = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _7ca3b487 = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _4891fb7e = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _0ccf0054 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/index' /* webpackChunkName: "" */))
const _30bcc370 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/edit' /* webpackChunkName: "" */))
const _276c1873 = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _5c5c6c23 = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _0df01988 = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _628063b5 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _1f6e8d86 = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _25f5665c = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _c14f39dc = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _eb3ee9fc = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _874bed70 = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _975381cc = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _000f928a = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _012897b0 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _6965a065 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _7a149d11 = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _4c091c38 = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _538b6f27 = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _5c6cb3d0 = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _07eb6cc4 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _c73beb22 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _05b46ae6 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _c742704a = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _bfef788a = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _9fa57fb8 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _89ac9b9a = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _11403eca = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _f2702dc2 = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _184d45e0 = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _467c53c8 = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _7e6becd2 = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _7f09d4c6 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _0bfbe758 = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _2effd1f4 = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _dffd973e = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _a936f91a = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _f08646e4 = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _b16d3a2e = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _11fd31d2 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _2ecc2e02 = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _013bf844 = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _373631c0 = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _322c0ebe = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _2e01061e = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _1204d862 = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _1d7e8345 = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _50ba1c1d = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _24682773 = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _603ed2e7 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _365618d6 = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _4590ad65 = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _4716e2b6 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _3c5e3eaa = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _1cac45f1 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _164dfc32 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _7e520390 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _23f2dbdb = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _5fe960a7 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _5e8eb310 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _3bf96724 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _fbb711c4 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _edaf1f18 = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _1deab3e9 = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _01bcbb92 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _2f090107 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _1abe03ce = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _a93dd456 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _285cb6f8 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _821f111c = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _abf1f8be = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _46173621,
    name: "foo"
  }, {
    path: "/ie",
    component: _a60ce88a,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _159959ec,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _7dbccf8d,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _29a175a3,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _4a89a70d,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _7070bfec,
    name: "appeals:frontend:show"
  }, {
    path: "/register/:referralCode?",
    component: _17d4344a,
    name: "auth:register"
  }, {
    path: "/login",
    component: _01824331,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _d5312866,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _bef1d2de,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _373e6622,
    children: [{
      path: "",
      component: _3da732e6,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _5c2bdce6,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _b50d274a,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _4ce2d249,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _68dbc5d0,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _66a75ff2
  }, {
    path: "/info/:uri*",
    component: _66a75ff2,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _fd943efe,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _3df99184,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _0c6dad4e,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _0fd8d878,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _3d8433d4,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _b4ab9cb8,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _1e52cc8e,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _c3737dec,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _dbb527b0,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _17de8e50,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _60277f76,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _1e3accfb,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _3acc8db4,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _6a7c8c3d,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _4b5fc2aa,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _065d609a,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _1fef5b7c,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _0653945e,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _baab2e6e,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _de1fdafe,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _aec34036,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _79a6599a,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _40f7c8e0,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _02d95f18,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _4b6308c9,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _61bf99c1,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _107f9d0e,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _67a16426,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _34b1a33b,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _14f19406,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _58b316e5,
    name: "marketing:frontend:shop"
  }, {
    path: "/welcome-answer/:answer",
    component: _433439b8,
    name: "marketing:user-journey:answer"
  }, {
    path: "/admin/mfis",
    component: _180fb1f5,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _7ca3b487,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _4891fb7e,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/mfi-info-messages",
    component: _0ccf0054,
    name: "mfis:admin:mfiInfoMessages"
  }, {
    path: "/admin/mfis/mfi-info-messages/:mfiInfoMessageId(\\d+)",
    component: _30bcc370,
    name: "mfis:admin:editMfiInfoMessages"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _276c1873,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _5c5c6c23,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _0df01988,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _628063b5,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _1f6e8d86,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _25f5665c,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _c14f39dc,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _eb3ee9fc,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _874bed70,
    children: [{
      path: "donations",
      component: _975381cc,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _000f928a,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _012897b0,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _6965a065,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _7a149d11,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _4c091c38,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _538b6f27,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _5c6cb3d0,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _07eb6cc4,
      children: [{
        path: "",
        component: _c73beb22,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _05b46ae6,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _c742704a,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _bfef788a,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _9fa57fb8,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _89ac9b9a,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _11403eca,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _f2702dc2,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _184d45e0,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _467c53c8,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _7e6becd2,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _7f09d4c6,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _0bfbe758,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _2effd1f4,
    children: [{
      path: "",
      component: _dffd973e,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _a936f91a,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _f08646e4,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _b16d3a2e,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _11fd31d2,
    children: [{
      path: "",
      component: _2ecc2e02,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _013bf844,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _373631c0,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _322c0ebe,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _2e01061e,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _1204d862,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _1d7e8345,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _50ba1c1d,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _24682773,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _603ed2e7,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _365618d6,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _4590ad65,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _4716e2b6,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _3c5e3eaa,
    children: [{
      path: "",
      component: _1cac45f1,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _164dfc32,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _7e520390,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _23f2dbdb,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _23f2dbdb,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _23f2dbdb,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _5fe960a7,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _5e8eb310,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _3bf96724,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _fbb711c4,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _edaf1f18,
    children: [{
      path: "",
      component: _1deab3e9,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _01bcbb92,
      children: [{
        path: "",
        component: _2f090107,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _1abe03ce,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _a93dd456,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _285cb6f8,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _821f111c,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _abf1f8be,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
